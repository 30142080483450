.studio-dark{
    background-color: rgb(17, 17, 17);
}

.studio-light,.studio-dark{
    // margin-top: $header-height;

    .studio-header{
        background-color: var(--#{$prefix}gray-500);
        height: 50px;
        padding: 5px;
        box-shadow: $box-shadow;

        .header{
            // margin-top: 30px;
            margin: 15px;
            box-shadow: $box-shadow;
        }
    }

    .board{
        width: 100%;
        height: calc(100vh - 0px);
    }
}


.browseFile{
    height: 500px;
        .window-scroll{
            height: 400px;
            .panel{
                min-width: 300px;
                max-width: 400px;
                height: 400px;
    
                .scroller{
    
                    height: 380px;
    
                    li{
                        width: 100%;
                        display: inline-block;

                       

                        &:hover, &.active  {
                            background-color: var(--#{$prefix}gray-300);
                            border-color: transparent;
                            box-shadow: $box-shadow;
                        }

                        a {
                            display: block;
                          
                        }
                        
                    }
    
            }
    }
        }
       
}

.window-board-runner{

    height: 724px;

    .content{
        height: 480px;

        .scroller{
            height: 400px;
        }
    }
   
}

.document-window-board-runner{

    height: 800px;

    .content{
        height: 480px;

        .scroller{
            height: 480px;
        }
    }


        .custom-app-runner{
            
            .board-execution-plan{
                height: 600px;
            }

            .scroller{
                height: 590px;
            }
        }   
   
}

.board-execution-plan{
       
        li {
            &.active {
                a {
                    // background-color: var(--#{$prefix}gray-300);
                    border-color: transparent;
                    box-shadow: $box-shadow;
                    .number {
                        background-color: $primary;
                        color: $white;
                      }
                }
            }
    
            a {
                display: block;
                padding: 14px 16px;
                color: var(--#{$prefix}gray-600);
                transition: all 0.4s;
                border-top: 1px solid var(--#{$prefix}border-color);
                border-radius: 4px;
    
                &:hover {
                    background-color: var(--#{$prefix}gray-300);
                    border-color: transparent;
                    box-shadow: $box-shadow;
                }
                .number {
                    display: inline-block;
                    width: 38px;
                    height: 38px;
                    line-height: 34px;
                    border: 2px solid $primary;
                    color: $primary;
                    text-align: center;
                    border-radius: 50%;
                    margin-right: .5rem;
                  }
            }
        }
}

.custom-app-runner{

    .scroller{
        height: 550px;
    }
}   

.app-list {

    .scroller {
        height: calc(100vh - 200px);
    }
    .app-card{
        &:hover  {
            background-color: var(--#{$prefix}gray-300);
            border-top: 1px solid var(--#{$prefix}border-color);
            box-shadow: $box-shadow;
        }

        .header{
            color: $white;
        }
    }
    
    .app-item-card{
        box-shadow: $box-shadow;
        width: 200px;
        height: 150px;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;


      
    
    
        

        .content{
          
            border: 1px solid red;
        }
        .screenshot{
            background-color: var(--#{$prefix}gray-300);
            width: 200px;
        }

        .description{
        }

        .assistant{
            height: 150px;
        }
}
}

.assistant-list{
    .scroller {
        height: calc(100vh - 200px);
    }
    .assistant-card{
        width: 320px;
        height: 210px ;
        &:hover  {
            background-color: var(--#{$prefix}gray-300);
            border-top: 1px solid var(--#{$prefix}border-color);
            box-shadow: $box-shadow;
            cursor: pointer;
        }

        .header{
            color: $white;
        }

        .app-add-button{
            font-size: 40px;
        }
    }
}

.assistant-avatars{
    .scroller{
        height: 450px;
       
    }

    .avatar{
        cursor: pointer;
        border-radius: 3px;
        line-height: 34px;
        text-align: center;
        border: 1px solid transparent;
        color: var(--#{$prefix}gray-600);

        &:hover {
            border-color: var(--#{$prefix}border-color);
        }
    }
}
.assistant-deploy{

    .scroller{
        height: 450px;
    }
}

.nodes{
    max-height: calc(100vh - 400px);

    .node-item{
        cursor: pointer;

        &:hover{
            background-color: var(--#{$prefix}gray-300);
        }
    }
}

//Designer

.handle-group{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 10px;
}

.handle-not-connected{
    height: 10px;
    width: 10px;
    background-color: $red;
}

.handle-connected{
    height: 10px;
    width: 10px;
    background-color: $green;
}


.handle-label {
    margin-left: 10px;
    mix-blend-mode: difference;
    color: white;
    font-weight: bold;
  }

  .namedHandle{

    // .handle{
    //     position: relative;
    //     top: 20px;
    //     height: 10px;
    //     width: 10px;
    // }

    .handle-not-connected{
        position: relative;
        top: 20px;
        height: 10px;
        width: 10px;
        background-color: $red;
    }
    
    .handle-connected{
        position: relative;
        top: 20px;
        height: 10px;
        width: 10px;
        background-color: $green;
    }


    .label {
        margin-left: 10px;
        mix-blend-mode: difference;
        color: white;
        font-weight: bold;
      }


  }

  .context-node{
    width: 100px;
    height: 50px;
    border-radius: 30px;
    text-align: justify;
    justify-content: center;

   
   
    .description{
        font-size: small;
        font-family: monospace;
    }
  }

