.crm-oppty{
        height: calc(100vh - 230px);

        .table-responsive{
            height: calc(100vh - 360px);
            overflow:auto; 

            thead tr:nth-child(1) th{
                position:sticky;
                top:0;
                margin: 0 0 0 0;
            }
        }

      

        .simplebar-track.simplebar-horizontal {
            display: none;
         }
        
        ::-webkit-scrollbar{
            // background-color: var(--#{$prefix}gray-300);
            // background-color: #ddd;
            // border: 1px solid red;

            ::-webkit-scrollbar-button{
                // background-color: #4d7fff;
                // border-radius: 5px;
            }

            
        }
}

.crm-board{

    .oppty-recent{
        height: calc(100vh - 450px);
    }
   

}
.crm-board-trend{
    height: calc(100vh - 350px);
    .oppty-trend{
        height: calc(100vh - 500px);
    }
}