.filemanager-sidebar{
    
    @media (min-width: 1200px){
        min-width: 230px;
        max-width: 230px;
    }

    @media (min-width: 1366px){
        min-width: 280px;
        max-width: 280px;
    }

    height: calc(100vh - 170px);
    margin-bottom: 0px;

}

.workspace{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;


  
    
}

.scroller-create-task-content{
    height: 300px;
}

.workspace-tasks{
    height: calc(100vh - 150px);

}

.workspace-documents{
    height: calc(100vh - 280px);
    width: 100%;
    margin-top: -0px;

    .leftbar{
        height: 100%;
    }

    .recentFiles {
        height: calc(100vh - 105px);

        .scroller{
            height: calc(100vh - 180px);
        }
    }

    .filesView{
        margin-top: -20px;
    }

    .add-options{
        position:absolute

    }
    
}

.workspace-documents-editor{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    .rich-text-editor{
        height: calc(100vh - 300px);
    }

    .editor-assistant-sidebar{

        @media (min-width: 1200px){
            min-width: 230px;
            max-width: 230px;
        }
    
        @media (min-width: 1366px){
            min-width: 280px;
            max-width: 280px;
        }

        

        .editor-assistant-sidebar-items{
            height: calc(100vh - 380px);
        }

    }

}

.workspace-document-landing{
    height: calc(100vh - 210px);

    input{
        width: 500px;
        height: 40px;
        box-shadow: 10px 15px 10px 10px rgba(54, 61, 71, 0.1);

    }
}
.workspace-document-structure{
    
    @media (min-width: 1200px){
        min-width: 230px;
        max-width: 230px;
    }

    @media (min-width: 1366px){
        min-width: 280px;
        max-width: 280px;
    }
}

.workspace-document-maincontent{
    min-height: calc(100vh - 220px);
    width: 100%;
    box-shadow: 2px 0 2px -4px rgba(54, 61, 71, 0.5), -2px 0 2px -4px rgba(54, 61, 71, 0.5);

    .confirm-structure-panel{
        height: 500px;

        button{
            height: 60px;
        }
    }

    .confirm-generation-panel{
        height: 500px;
    }

}

.template-modal{
    height: 800px;
}

.window-medium{
    height: 700px;
}
.template-list {
    height: calc(100vh - 265px);

    .template-editor{
        min-height: calc(100vh - 320px);
        width: 100%;
        box-shadow: 2px 0 2px -4px rgba(54, 61, 71, 0.5), -2px 0 2px -4px rgba(54, 61, 71, 0.5);
    
        .confirm-structure-panel{
            height: 500px;
    
            button{
                height: 60px;
            }
        }
    
        .confirm-generation-panel{
            height: 500px;
        }

        .scroller{
            height: calc(100vh - 300px);
        }
    
    }

    
}

.template-config{
    .files {
        margin: 0;
        height: 500px;

    
        li {
            &.active {
                a {
                    background-color: var(--#{$prefix}gray-300);
                    border-color: transparent;
                    box-shadow: $box-shadow;
                }
            }
    
            a {
                display: block;
                padding: 14px 16px;
                color: var(--#{$prefix}gray-600);
                transition: all 0.4s;
                border-top: 1px solid var(--#{$prefix}border-color);
                border-radius: 4px;
    
                &:hover {
                    background-color: var(--#{$prefix}gray-300);
                    border-color: transparent;
                    box-shadow: $box-shadow;
                }
            }
        }
    }

    .template-structure{
        min-height: 100%;

        .template-structure-content{
            height: 360px;
        }
    }
}

.document-generation-template{
    .structure{
        height: 600px;
    }
}

.todolist{
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // position: relative;
    height: calc(100vh - 300px);


    .leftbar{
        min-width: 400px;
    }

    .todoitem{
        height: calc(100vh - 250px);
    }

    .todoItemContent{
        height: calc(100vh - 400px);
    }
    .todoleftBar{
        height: calc(100vh - 300px);
    }
}


.todo-resources-list{

    height: 700px;

    .content-view{
        height: 500px;
    }
}


.todo-resources-leftsidebar {
    min-height: 100%;

    .todo-resources-leftsidebar-nav {
        .nav {
            background-color: var(--#{$prefix}custom-white);
        }

        .tab-content {
            min-height: 488px;
        }

        .resources-list {
            margin: 0;
        
            li {
                &.active {
                    a {
                        background-color: var(--#{$prefix}gray-300);
                        border-color: transparent;
                        box-shadow: $box-shadow;
                    }
                }
        
                a {
                    display: block;
                    padding: 14px 16px;
                    color: var(--#{$prefix}gray-600);
                    transition: all 0.4s;
                    border-top: 1px solid var(--#{$prefix}border-color);
                    border-radius: 4px;
        
                    &:hover {
                        background-color: var(--#{$prefix}gray-300);
                        border-color: transparent;
                        box-shadow: $box-shadow;
                    }
                }
            }
        }
    }
    
}

.item-banner{
    // background-color: $header-dark-bg;
    // color: white;
    box-shadow: $box-shadow;
    cursor: pointer;

}

.todolist-items-list {
    margin: 0;

    li {
        &.active  {
            a {
                background-color: var(--#{$prefix}custom-white);
                border-color: transparent;
                box-shadow: $box-shadow;
            }
        }

        a {
            display: block;
            padding: 14px 16px;
            color: var(--#{$prefix}gray-600);
            transition: all 0.4s;
            border-top: 1px solid var(--#{$prefix}border-color);
            border-radius: 4px;

            &:hover {
                background-color: var(--#{$prefix}custom-white);
                border-color: transparent;
                box-shadow: $box-shadow;
            }
        }
    }
}

.deploy-assistant{

    .knowledgebase-config{

        height: 500px;

        .leftbar{
            width: 400px;
            .scroller{
                height: 450px;
    
                li{
                    padding: 5px 8px;
                    cursor: pointer;

                    &:hover,&.active{
                        background-color: var(--#{$prefix}gray-300);
                        border-color: transparent;
                    }
                }
            }
    
        }
    
        .rightbar {
            width: 700px;
            .scroller-table{
                height: 250px;
            }

            .scroller-browser-datasource{
                height: 150px;
            }
            .scroller-browser-internet{
                height: 250px;
            }
        }
    
    }

   

    .knowledgebase{
        .scroller{
            height: 70px;

            li{
                padding: auto;
                // background-color: var(--#{$prefix}gray-300);
                padding: 2px 10px;
                box-shadow: $box-shadow;
                transition: all 0.4s;
                    border-bottom: 1px solid var(--#{$prefix}border-color);
            }
        }

        
    }

    .assistant-types{
        display: flex;
        flex-direction: row;

        .assistant-type{
            flex: 1 0 33.333%;
            height: 90px;
            cursor: pointer;
            border:2px solid rgba($sidebar-dark-bg,0.3);

            &:hover, .selected{
                border:3px solid #ea7768;
            }
        }

       .selected{
            border:3px solid #ea7768;
        }
    }

}

//Datasources 

.datasources-panel{

    height: calc(100vh - 240px);

    .wizard{
        .content{
            height: calc(100vh - 400px);
        }
    } 

    .connectivy-local{

        .dropzone,.selected-files{
            height: calc(100vh - 500px)
        }

        .scroller{
            height: calc(100vh - 600px)
        }
    }

    .connectivy-web{
        .scroller{
            height: calc(100vh - 600px)
        }
    }

    .react-table{
        height: calc(100vh - 300px);
    }
}


.export-window{


    min-height: 400px;

    .export-view-panel{

        .documents{

            .scroller{
                height: 100px;

                input{
                    border: 0px;
                    width: 400px;
                }
            }
            li{
                padding: auto;
                // background-color: var(--#{$prefix}gray-300);
                padding: 4px 10px;
                box-shadow: $box-shadow;
                transition: all 0.4s;
                border-bottom: 1px solid var(--#{$prefix}border-color);
            }
        }

      
    }
}


.workspace-resources-leftbar {
    width: 300px;
    float: left;
    padding: 20px;
    border-radius: 5px;
    height: calc(100vh - 120px);

    .datasources-list {
        .datasources-scroller{
            max-height: calc(100vh - 500px);

            a {
                display: block;
                color: var(--#{$prefix}gray-600);
                line-height: 24px;
                padding: 8px 5px;
                &.active {
                color: $danger;
                font-weight: 500;
                }
            }
        }

      
       
        }
    }

    .assistants-list{
        .assistants-scroller{
            max-height: calc(100vh - 450px);
        }
    }

    .workspace-resources-rightbar{
        margin-left: 310px;
        height: calc(100vh - 180px);

        .recentFiles {
            height: calc(100vh - 200px);
    
            .scroller{
                height: calc(100vh - 200px);
            }
        }

        .template-list{
            height: calc(100vh - 220px);
        }

    }
.tabular-launcher{

    min-height: 750px;
    overflow: hidden;
    
    .tabular{
        min-height: 500px;

        .table-responsive{
            height: 500px;
            overflow:auto; 

            thead tr:nth-child(1) th{
                position:sticky;
                top:0;
                margin: 0 0 0 0;
            }
        }

        .simplebar-track.simplebar-horizontal {
            display: none;
         }

         .chartsView{
            height: 500px;

            .chartItem{
                height: 250px;
            }
        }
    }

    .toolbar{

        .tool{
            width: 120px;
            text-align:center;
            cursor: pointer;
                background-color: var(--#{$prefix}gray-300);

            &:hover{
                border-color: transparent;
                box-shadow: $box-shadow;
            }
        }
    }
}
   

.file-task-window{
    height: 800px;

    .scenario-picker{
        height: 500px;

        .scenario{
            cursor: pointer;

            &:hover{
                background-color: var(--#{$prefix}gray-300);
                    border-color: transparent;
                    box-shadow: $box-shadow;
            }
        }
    }   

    .file-picker{
        height: 500px;

        .dropzone,.selected-files{
            height: 500px;
        }

        .scroller{
            height: 450px;

        }

    }
}

.app-list-mini{

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .scroller{
        height: 400px;
    }


    .app{
        // width: 300px;
        height: 150px;
        // overflow: hidden;
        cursor: pointer;
        flex: 1 0 280px;
        max-width: 300px;

        &:hover{
            background-color: var(--#{$prefix}gray-300);
                border-color: transparent;
                box-shadow: $box-shadow;
        }
        .app-content{
            height: 110px;
            overflow: hidden;
        }
        .app-actions{
            height: 20px;
        }

        .app-add-button{
            font-size: 40px;
        }
    }
}

.popular-prompt{
    &:hover{
        background-color: var(--#{$prefix}gray-300);
                border-color: transparent;
                box-shadow: $box-shadow;

                .card{
                    background-color: var(--#{$prefix}gray-300);
                    border-color: transparent;
                    box-shadow: $box-shadow;
                }
    }
   
}

.task-files-list{

    .scroller{
        height: 400px;
    }
    li {
        padding: 14px 16px;
        transition: all 0.4s;
        border-radius: 4px;
        border-top: 1px solid var(--#{$prefix}border-color);
        cursor: pointer;

        &.active {
            background-color: var(--#{$prefix}gray-300);
            border-color: transparent;
            box-shadow: $box-shadow;
        }

        &:hover {
            background-color: var(--#{$prefix}gray-300);
            border-color: transparent;
            box-shadow: $box-shadow;
        }
    }
}

.tasks-files-starter{

    height: 400px;

    .starter-button{
        
        width: 200px;
        height: 70px;
    }
}


.tasks-files-content-view{
    
    .scroller{
        height: 400px;
    }
}

.emptytasks{
    height: calc(100vh - 400px);
}

.prompt-store-browser{
 
}

.prompt-store{

    height: calc(100vh - 180px);

    .categories{
        width: 300px;
    }

    .scroller{
        height: calc(100vh - 220px);
        // padding: 0;

        .prompts-list{

            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .prompt{
    
                // width: 250px;
                height: 150px;
                cursor: pointer;
                margin: 5px;
                flex: 1 0 250px;
                max-width: 300px;
    
                .prompt-add-button{
                    font-size: 40px;
                }
    
                &:hover{
                    background-color: var(--#{$prefix}gray-300);
                        border-color: transparent;
                        box-shadow: $box-shadow;
                }
            }
        }
        

 
    }

    .menu{
        li{
            padding: 10px 10px;
            cursor: pointer;

               
            box-shadow: $box-shadow;
            transition: all 0.4s;
            border-bottom: 1px solid var(--#{$prefix}border-color);

            &:hover,&.active{
                background-color: var(--#{$prefix}gray-300);
                border-color: transparent;
            }
        }
    }
}

.omni-app{
    display: flex;
    flex-direction: column;
    height: 100vh;

    .context{
        // border: dashed;
        // height: calc(100vh - 200px);
        flex: auto;
    }

    .app-input{
        margin-left:30px;
        margin-right:30px
    }

    .runner{
        .scroller{
            height: calc(100vh - 200px)

        }
    }
}

