.monitoring-board{

    height: calc(100vh - 180px);

    .card-item{
        height: calc(100vh - 200px);
        
        .scroller{
            height: calc(100vh - 200px);
        }

        .scroller-graph{
            height: calc(100vh - 100px);
        }
    }
}

.resource-profile{

    .scroller{
        height: calc(100vh - 300px);
    }
}