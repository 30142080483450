.autosuggest{

    .request-list{
        height: 540px;

        li{
            a {
                display: block;
                padding: 14px 16px;
                color: var(--#{$prefix}gray-600);
                transition: all 0.4s;
                border-top: 1px solid var(--#{$prefix}gray-400);
                border-radius: 4px;
    
                &:hover {
                    background-color: var(--#{$prefix}gray-400);
                    border-color: transparent;
                    box-shadow: $box-shadow;
                }

                &.active{
                    background-color: var(--#{$prefix}gray-400);
                    border-color: transparent;
                    box-shadow: $box-shadow;
                }
            }
        }
    }

    .resolution-suggestion-content-scroller{
        height: 200px;
    }
}

.support-teams{
    min-height: 500px;

    .scroller{
        height: 500px;
    }
    
    .app-list-mini{

        .app{
            width: 300px;
            height: 180px;
            overflow: hidden;
            cursor: pointer;
    
            &:hover{
                background-color: var(--#{$prefix}gray-300);
                    border-color: transparent;
                    box-shadow: $box-shadow;
            }
            .app-content{
                height: 110px;
                overflow: hidden;
            }
            .app-actions{
                height: 20px;
            }
    
            .app-add-button{
                font-size: 40px;
            }
        }
    }

    .assistant-list{

        .app{
            width: 250px;
            height: 150px;
            overflow: hidden;
            cursor: pointer;
    
            &:hover{
                background-color: var(--#{$prefix}gray-300);
                    border-color: transparent;
                    box-shadow: $box-shadow;
            }
            .app-content{
                height: 110px;
                overflow: hidden;
            }
            .app-actions{
                height: 20px;
            }
    
            .app-add-button{
                font-size: 40px;
            }
        }
    }

    
}

.board{
    height: calc(100vh - 0px);

    .board-card{
        height: calc(100vh - 280px);
        .scroller{
            height: calc(100vh - 420px);
        }
     

    }

    .board-card-mini{
        .scroller{
            height: calc(100vh - 680px);
        }
    }
}

.board-stats-card{
    height: calc(100vh - 280px);
}