.assistant-leftsidebar {
    min-height: 100%;

    .assistant-leftsidebar-nav {
        .nav {
            background-color: var(--#{$prefix}custom-white);
        }

        .tab-content {
            min-height: 488px;
        }
    }
    
}

.assistant-rightsidebar {
    height: calc(100vh - 200px);
    width: 100%;
    .assistant-datasources-body{
        height: calc(100vh - 400px);
    }
}

.assistant-chat {
    // display: block;
    // flex-direction: column;
    // position: relative;
    box-shadow: 3px 5px 10px 10px rgba(54, 61, 71, 0.1);
    height: calc(100vh - 120px);
    width: 100%;
    // width: 100%;
    // background-color: var(--#{$prefix}custom-white);


    li {
        clear: both;
    }
    .chat-day-title {
        position: relative;
        text-align: center;
        margin-bottom: 24px;

        .title {
            background-color: var(--#{$prefix}custom-white);
            position: relative;
            z-index: 1;
            padding: 6px 24px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            background-color: var(--#{$prefix}border-color);
            top: 10px;
        }

        .badge {
            font-size: 12px;
        }
    }

    .chat-history{
        width: 100%;
        // height: 100%;
        // display: flex;
        // flex-direction: column;
        overflow: auto;
        padding: 0 1rem 0 1rem;
    }

    .chat-content{
        height: calc(100vh - 320px);
        .scroller{
           height:95%
         }
    }

    .scroller-references{
        height: 400px;
        width: 500px;
     }

    .chat-content-reduced{
        height: calc(100vh - 430px);
        .scroller{
           height:100%
         }
    }
    
    .conversation-list {
        margin-bottom: 24px;
        display: inline-block;
        position: relative;

        .ctext-wrap {
            padding: 12px 24px;
            background-color: rgba($primary, 0.1);
            border-radius: 8px 8px 8px 0px;
            overflow: hidden;

            .conversation-name {
                font-weight: $font-weight-semibold;
                color: $primary;
                margin-bottom: 4px;
            }
        }

        .dropdown {
            float: right;

            .dropdown-toggle {
                font-size: 18px;
                padding: 4px;
                color: var(--#{$prefix}gray-600);

                @media (max-width: 575.98px) {
                    display: none;
                }
            }

            .dropdown-menu {
                box-shadow: $box-shadow;
                border: 1px solid var(--#{$prefix}border-color);
            }
        }

        .chat-time {
            font-size: 12px;
        }
    }

    .right {
        .conversation-list {
            float: right;

            .ctext-wrap {
                background-color: var(--#{$prefix}light);
                text-align: right;
                border-radius: 8px 8px 0px 8px;
            }

            .dropdown {
                float: left;
            }

            &.last-chat {
                .conversation-list {
                    &:before {
                        right: 0;
                        left: auto;
                    }
                }
            }
        }
    }

    .last-chat {
        .conversation-list {
            &:before {
                content: "\F0009";
                font-family: "Material Design Icons";
                position: absolute;
                color: $primary;
                right: 0;
                bottom: 0;
                font-size: 16px;

                @media (max-width: 575.98px) {
                    display: none;
                }
            }
        }
    }

}
.assistant-input{
    min-height: 100px;
    max-height: 200px;
    .assistant-input-section {
        border-top: 2px solid var(--#{$prefix}border-color);
        background-color: var(--#{$prefix}light) !important;
        border-color: var(--#{$prefix}light) !important;
        border-radius: 5px;
        padding-right: 120px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
        width: 100%;
        min-height: 50px;
        textarea,input{
            background-color: var(--#{$prefix}light) !important;
            border-color: var(--#{$prefix}light) !important;
            color:   var(--#{$prefix}body-color);
        }

        .attachment{

        }
    }
}


.assistants-conversations{
    height: calc(100vh - 140px);

    .conversation{
        height: 80px;
        cursor: pointer;
        border-bottom: 1px solid var(--#{$prefix}border-color);

        &:hover {
            background-color: rgba($secondary,0.4);
            border-color: transparent;
            box-shadow: $box-shadow;
        }

        &.active  {
            // a {
                background-color: rgba($secondary,0.4);
                border-color: transparent;
                box-shadow: $box-shadow;
            // }
        }

    }
}

.landing-chat{

    // margin: 5%;
    margin-top: 15%;

    .chat-info{
        height: 50%;
    }

    .chat-suggestions{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        
        .chat-info-headline{
            // height: 100px;
            max-width: 300px;
            flex: 1 0 20%;
            cursor: pointer;
            box-shadow: 3px 5px 10px 10px rgba(54, 61, 71, 0.1);
            &:hover {
                    border:2px solid #ea7768;
                box-shadow: 3px 5px 10px 10px rgba(6, 91, 211, 0.1);
            }
        }
    }

   
    .agent-info{
        width: 550px;
        p{
            text-align: justify;
        }
    }
   
}

.explorer-assistant{

    min-height: 100%;
    width: 3OOpx;
}

.omnichat{
    display: flex;
    flex-direction: column;
    box-shadow: 3px 5px 10px 10px rgba(54, 61, 71, 0.1);
    height: calc(100vh - 200px);
    width: 100%;
    // background-color: var(--#{$prefix}custom-white);


    li {
        clear: both;
    }
    .chat-day-title {
        position: relative;
        text-align: center;
        margin-bottom: 24px;

        .title {
            background-color: var(--#{$prefix}custom-white);
            position: relative;
            z-index: 1;
            padding: 6px 24px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            background-color: var(--#{$prefix}border-color);
            top: 10px;
        }

        .badge {
            font-size: 12px;
        }
    }

    .chat-history{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding: 0 1rem 0 1rem;
    }

    .scroller{
        height: calc(100vh - 200px);
        width: 480px;
    }

    
    .conversation-list {
        margin-bottom: 24px;
        display: inline-block;
        position: relative;

        .ctext-wrap {
            padding: 12px 24px;
            background-color: var(--#{$prefix}chat-answer-bg);
            border-radius: 8px 8px 8px 0px;
            overflow: hidden;


            .conversation-name {
                font-weight: $font-weight-semibold;
                color: $primary;
                margin-bottom: 4px;
            }
        }

        .dropdown {
            float: right;

            .dropdown-toggle {
                font-size: 18px;
                padding: 4px;
                color: var(--#{$prefix}gray-600);

                @media (max-width: 575.98px) {
                    display: none;
                }
            }

            .dropdown-menu {
                box-shadow: $box-shadow;
                border: 1px solid var(--#{$prefix}border-color);
            }
        }

        .chat-time {
            font-size: 12px;
        }
    }

    .right {
        .conversation-list {
            float: right;

            .ctext-wrap {
                background-color: var(--#{$prefix}light);
                text-align: right;
                border-radius: 8px 8px 0px 8px;
            }

            .dropdown {
                float: left;
            }

            &.last-chat {
                .conversation-list {
                    &:before {
                        right: 0;
                        left: auto;
                    }
                }
            }
        }
    }

    .last-chat {
        .conversation-list {
            &:before {
                content: "\F0009";
                font-family: "Material Design Icons";
                position: absolute;
                color: $primary;
                right: 0;
                bottom: 0;
                font-size: 16px;

                @media (max-width: 575.98px) {
                    display: none;
                }
            }
        }
    }

}

.omnichat-banner{
    background-color: #ea7768;
}

.custom-input-chat{

    .mentions-container{
    }

    .mention__mentions {
      }

      .mentions__highlighter {
      }

    .mension-item{

        padding: 14px 16px;
        color: var(--#{$prefix}gray-600);
        transition: all 0.4s;
        border-top: 1px solid var(--#{$prefix}border-color);
        // border-radius: 4px;
        background-color: var(--#{$prefix}custom-white);
        width: 250px;

        &:hover {
            background-color: var(--#{$prefix}gray-300);
            border-color: transparent;
            box-shadow: $box-shadow;
        }
    }

    .mentions--singleLine .mentions__control {
        // display: inline-block;
        // width: 100%;
      }


    .mentions__suggestions {

        // border: 1px solid var(--#{$prefix}border-color);
        // font-size: 10pt;
        // box-shadow: $box-shadow-lg;
        // animation-name: DropDownSlide;
        // animation-duration: 0.3s;
        // animation-fill-mode: both;
        // margin: 10;
        // position: absolute;
        // z-index: 1000;
        // width: 300px;
        // height: 400px;

      }

      .mentions__mention {
        // position: relative;
        // z-index: 1;
        // color: blue;
        // // text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
        // //   -1px -1px 1px white;
        // text-decoration: underline;
        // pointer-events: none;
        // background-color: red;
      }

      .mentions__suggestions__item--focused {
      }
    .mentions__input {
        
        height: 150px;
      }

}

.generationPanel{
    min-height: 400px;
    textarea{
        box-sizing: border-box; 
        height: 100%;
    }
}

.markdown {
    ul,
    ol {
        white-space: normal;
    }

    li {
        margin-bottom: 0.5rem;
    }
   

    &__table {
        overflow: auto;
        max-height: 400px;
    }
    
    pre code{
        color:$primary;
    }

    h1,h2,h3,h4{
        font-size: 14px;
        padding-top: 5px;
    }
}

.main-chat{
    .rightbar{
        .header{
            height: 70px;
        }
    }
}

.chat-references{

    .modal{
        
    }

    .internet{
        max-width: 100%;

        .resource{
            width: 230px;
            height: 80px;
            cursor: pointer;

            .title{
                font-size: 11px;
                height: 40px;
                margin: 0;
                // font-family: monospace;
            }

            .source{
                font-size: 11px;
            }

            :hover{
                background-color: var(--#{$prefix}gray-100);
                border-color: transparent;
                box-shadow: $box-shadow;
            }
        }
    }
}

.chat-recommendations{

    .assistant{

        width: 100px;
        height: 30px;

        :hover{
            cursor: pointer;
            background-color: var(--#{$prefix}chat-answer-bg);
        }
    }
}
.share-panel{
    height: 500px;

    .share-panel-content{
        height: 400px;
    }
}

.quote-view-tooltip {

     .tooltip-inner{
    max-width: 400px;
    color: black;
    opacity: 1!important;   
    background-color: white;
    border-color: $info;
    text-align: justify;
    z-index: 10000;

  background: white; 
     }
}


.quote-view-tooltip.in{
    opacity: 1!important;    
}

.suggestions{
    display: flex;
    margin: auto;

    .suggestion-button{
        cursor: pointer;
        font-size: 10px;
        font-weight: bold;
        
        &:hover{
            background-color: rgba($info, 0.7);
            color: black;
        }
    
    }
}
@keyframes bounce {
    to { transform: scale(1.1); }
  }

.search-gpt{

    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);

    .landing{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .search{
            min-width: 500px;
            box-shadow: 4px 4px 4px rgba($sidebar-dark-bg, 0.34), 
            2px 2px 2px rgba(0, 0, 0, 0.3);

            textarea{
                width: 450px;
                border:0px solid black;
                background-color: transparent;
                left: 30px;
                font-size: 14px;
                resize: none;
            }

            .search-icon{
                position: relative;
                top: 32px;
                left: -10px;
            }

            .send{
                position: relative;
                top: -30px;
            }
        } 

        .suggestions{
            .item{
                height: 90px;
                flex: 0 1 33.333%;
                font-size: 11px;
                margin: auto;
                max-width: 300px;
                cursor: pointer;
                box-shadow: 4px 4px 4px rgba($sidebar-dark-bg, 0.34), 
            2px 2px 2px rgba(0, 0, 0, 0.3);

                &:hover{
                    border:2px solid #ea7768;
                }
            }
        }
    }

    .chat{
        display: flex;
        flex-direction: column;
        height: calc(100vh - 180px);

        .filters{
        }

        .context{
            // border: dashed;
            // height: calc(100vh - 200px);
            flex: auto;
            height: calc(100vh - 300px);

            .datasource{
                cursor: pointer;
            }

            .search-chat-panel{
                .scroller{
                    height: calc(100vh - 330px);
                }
            }

           

            .chat-scroller{
                height: calc(100vh - 400px);
                flex: auto;
            }

            .btn-filter {
                background-color: $sidebar-dark-bg;
            }



        }
    
       


    }

   
}

.chat-header{
    background-color: rgba($sidebar-dark-bg,0.9);
    font-family: Arial, Helvetica, sans-serif;
}

.btn-searchGPT{
    background-color: $sidebar-dark-bg;
    color: white;
}

.search-omni-chat{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 0px);

    .search-chat-history{
        flex: auto;

        li {
            clear: both;
        }

        .chat-day-title {
            position: relative;
            text-align: center;
            margin-bottom: 24px;
    
            .title {
                background-color: var(--#{$prefix}custom-white);
                position: relative;
                z-index: 1;
                padding: 6px 24px;
            }
    
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                left: 0;
                right: 0;
                background-color: var(--#{$prefix}border-color);
                top: 10px;
            }
    
            .badge {
                font-size: 12px;
            }
        }

        overflow: auto;
        padding: 0 1rem 0 1rem;

        .conversation-list {
            margin-bottom: 24px;
            display: inline-block;
            position: relative;
    
            .ctext-wrap {
                padding: 12px 24px;
                background-color: rgba($primary, 0.1);
                border-radius: 8px 8px 8px 0px;
                overflow: hidden;
    
                .conversation-name {
                    font-weight: $font-weight-semibold;
                    color: $primary;
                    margin-bottom: 4px;
                }
            }
    
            .dropdown {
                float: right;
    
                .dropdown-toggle {
                    font-size: 18px;
                    padding: 4px;
                    color: var(--#{$prefix}gray-600);
    
                    @media (max-width: 575.98px) {
                        display: none;
                    }
                }
    
                .dropdown-menu {
                    box-shadow: $box-shadow;
                    border: 1px solid var(--#{$prefix}border-color);
                }
            }
    
            .chat-time {
                font-size: 12px;
            }
        }
    
        .right {
            .conversation-list {
                float: right;
    
                .ctext-wrap {
                    background-color: var(--#{$prefix}light);
                    text-align: right;
                    border-radius: 8px 8px 0px 8px;
                }
    
                .dropdown {
                    float: left;
                }
    
                &.last-chat {
                    .conversation-list {
                        &:before {
                            right: 0;
                            left: auto;
                        }
                    }
                }
            }
        }
    
        .last-chat {
            .conversation-list {
                &:before {
                    content: "\F0009";
                    font-family: "Material Design Icons";
                    position: absolute;
                    color: $primary;
                    right: 0;
                    bottom: 0;
                    font-size: 16px;
    
                    @media (max-width: 575.98px) {
                        display: none;
                    }
                }
            }
        }

    }
    .search-chat-input{
        // border-top: 2px solid var(--#{$prefix}border-color);
        // background-color: var(--#{$prefix}light) !important;
        // border-radius: 5px;
        // padding-right: 120px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
        // width: 100%;
        min-height: 50px;
        textarea,input{
          resize: none;
        }
} 
}




.search-chat{

    .chat-day-title {
        position: relative;
        text-align: center;
        margin-bottom: 24px;

        .title {
            background-color: var(--#{$prefix}custom-white);
            position: relative;
            z-index: 1;
            padding: 6px 24px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            background-color: rgba($sidebar-dark-bg,0.2);
            top: 10px;
        }

        .badge {
            font-size: 12px;
        }
    }
    .search-chat-history{
        flex: auto;

        li {
            clear: both;
        }

       

        overflow: auto;
        padding: 0 1rem 0 1rem;

        .conversation-list {
            margin-bottom: 24px;
            display: inline-block;
            position: relative;
    
            .ctext-wrap {
                padding: 12px 24px;
                background-color: rgba($primary, 0.1);
                border-radius: 8px 8px 8px 0px;
                overflow: hidden;
    
                .conversation-name {
                    font-weight: $font-weight-semibold;
                    color: $primary;
                    margin-bottom: 4px;
                }
            }
    
            .dropdown {
                float: right;
    
                .dropdown-toggle {
                    font-size: 18px;
                    padding: 4px;
                    color: var(--#{$prefix}gray-600);
    
                    @media (max-width: 575.98px) {
                        display: none;
                    }
                }
    
                .dropdown-menu {
                    box-shadow: $box-shadow;
                    border: 1px solid var(--#{$prefix}border-color);
                }
            }
    
            .chat-time {
                font-size: 12px;
            }
        }
    
        .right {
            .conversation-list {
                float: right;
    
                .ctext-wrap {
                    background-color: var(--#{$prefix}light);
                    text-align: right;
                    border-radius: 8px 8px 0px 8px;
                }
    
                .dropdown {
                    float: left;
                }
    
                &.last-chat {
                    .conversation-list {
                        &:before {
                            right: 0;
                            left: auto;
                        }
                    }
                }
            }
        }
    
        .last-chat {
            .conversation-list {
                &:before {
                    content: "\F0009";
                    font-family: "Material Design Icons";
                    position: absolute;
                    color: $primary;
                    right: 0;
                    bottom: 0;
                    font-size: 16px;
    
                    @media (max-width: 575.98px) {
                        display: none;
                    }
                }
            }
        }

    }
    .search-chat-input{
        // border-top: 2px solid var(--#{$prefix}border-color);
        // background-color: var(--#{$prefix}light) !important;
        // border-radius: 5px;
        // padding-right: 120px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
        // width: 100%;
        min-height: 50px;
        textarea,input{
          resize: none;
        }
    }
    
    [data-isOn="false"]{
        display: none;
    }
}

.search-chat[data-isOn="false"]{
    opacity: none;
}

.search-chat-panel[data-isOn="true"]{
    display: none;
}

.custom-background {
    background-color: $sidebar-dark-bg;
    color: white;

    &:hover{
        background-color: $sidebar-dark-bg;
        color: white;
    }
}

.custom-border{
    border: 0.2px solid $sidebar-dark-bg;
}

.custom-cursor{
    width: 15px;
    height: 15px;
    display: inline-block;
    content: " ";
}